var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-ticket",style:(("border-bottom-color: " + (_vm.getInfoByStatus(_vm.ticket.ESTADO).color))),attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"d-flex gap-2"},[_c('h4',{style:({
        fontSize: '14px',
        wordBreak: 'break-word',
        lineHeight: '1.2',
      })},[_c('strong',[_vm._v("#"+_vm._s(_vm.ticket.IDCOMPRA))]),_vm._v(" - "+_vm._s(_vm.ticket.DESCRIPCION_CATEGORIA)+" ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('div',{staticClass:"image-container d-flex gap-3",style:({ gap: '15px', alignItems: 'center' })},[(_vm.ticket.URLFOTO_CATEGORIA)?_c('img',{style:({ objectFit: 'cover' }),attrs:{"width":"100","height":"100","draggable":"false","src":_vm.loadImg(_vm.ticket.URLFOTO_CATEGORIA),"alt":""}}):_vm._e(),_c('div',[(
            _vm.ticket.HABILITARCOMPRAANTICIPADA == '1' &&
              _vm.ticket.HABILITARCAMBIOFECHA == '0'
          )?_c('div'):_c('p',{staticClass:"text-detail text-medium fw-500 mb-2"},[_c('strong',[_vm._v(_vm._s(_vm.labels.visitDate[_vm.lang])+":")]),_c('span',[_vm._v(_vm._s(_vm.ticket.FECHAVISITA))])]),_c('p',{staticClass:"text-detail text-medium fw-500 mb-2"},[_c('strong',[_vm._v(_vm._s(_vm.labels.amount[_vm.lang])+":")]),_c('span',[_vm._v("S/ "+_vm._s(_vm.ticket.IMPORTE))])]),_c('p',{staticClass:"text-detail text-medium fw-700 mt-4",style:(("color: " + (_vm.getInfoByStatus(_vm.ticket.ESTADO).color)))},[_vm._v(" "+_vm._s(_vm.getInfoByStatus(_vm.ticket.ESTADO).message[_vm.lang])+" ")])])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.getInfoByStatus(_vm.ticket.ESTADO).color,"depressed":"","rounded":"","text":"","to":_vm.redirectTo
          ? _vm.redirectTo
          : {
              name: 'TicketDetail',
              params: {
                purchaseId: _vm.ticket.IDCOMPRA,
                parkId: _vm.ticket.IDPARQUE,
              },
            }}},[_vm._v(_vm._s(_vm.getTextBtnByTicket(_vm.ticket).message[_vm.lang])+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }