<template>
  <div class="template-tickets">
    <v-container>
      <v-row class="title-sticky">
        <v-col cols="12" lg="12">
          <TitleSection
            :title="`${titlePage[lang]}`"
            btnBack 
            backHome
          ></TitleSection>
          <!-- :subtitle="`${subtitle[lang]} ${park ? park.DESCRIPCION : ''}`" -->
        </v-col>
      </v-row>

      <template v-if="user">
        <!-- <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            mt-5
          "
        >
          <p class="text-center mb-0">
            <small>{{ labelBtnSearchUser[lang] }}</small>
          </p>
          <div class="quiz-btn d-flex justify-content-center py-4 pt-2">
            <button
              class="search-button text-medium fw-600 readable"
              type="button"
              @click.prevent="redirectToSearch"
              :aria-label="titleBtnSearch[lang]"
            >
              <span class="mr-1">{{ titleBtnSearch[lang] }}</span>
              <v-icon>search</v-icon>
            </button>
          </div>
        </div> -->
        <v-data-iterator
          class="pt-4"
          v-if="shoppingList && shoppingList.length > 0"
          :items="shoppingList"
          :items-per-page="5"
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="(item, idx) in items"
                :key="getRandomId() + item.IDCOMPRA + idx"
                cols="12"
                lg="12"
                sm="12"
              >
                <CardTicket :ticket="item"></CardTicket>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <div v-else class="text-center my-10 text-medium fw-400">
          {{ notFoundMsg[lang] }}
        </div>
      </template>

      <template v-else>
        <div>
          <div class="text-center my-10 text-medium fw-400">
            {{ noUserMsg[lang] }}
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <p class="text-center mb-0">
              <small>{{ labelBtnSearch[lang] }}</small>
            </p>
            <div class="quiz-btn d-flex justify-content-center py-4 pt-2">
              <button
                class="search-button text-medium fw-600 readable"
                type="button"
                @click.prevent="redirectToSearch"
                :aria-label="titleBtnSearch[lang]"
              >
                <span class="mr-1">{{ titleBtnSearch[lang] }}</span>
                <v-icon>search</v-icon>
              </button>
            </div>
          </div>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import CardTicket from "./components/CardTicket.vue";
import TitleSection from "@/components/titles/TitleSection";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    CardTicket,
    TitleSection,
  },
  data() {
    return {
      titlePage: {
        en: "My tickets",
        es: "Mis entradas",
      },
      subtitle: {
        en: "for the",
        es: "para el",
      },
      notFoundMsg: {
        es: "No se encontraron entradas para este parque",
        en: "No tickets found for this park",
      },
      noUserMsg: {
        es: "Necesitas iniciar sesión para ver la lista de tus entradas adquiridas",
        en: "You need to log in to see the list of your purchased tickets",
      },
      labelBtnSearch: {
        es: "¿No tienes una cuenta?",
        en: "You do not have an account?",
      },
      labelBtnSearchUser: {
        en: "Can't find your purchase/registration?",
        es: "¿No encuentras tu compra/registro?",
      },
      titleBtnSearch: {
        es: "Busca tus entradas aquí",
        en: "Find your tickets here",
      },
      titleBtnSearchUser: {
        es: "Busca otras entradas con aquí",
        en: "Find other tickets here",
      },
    };
  },

  computed: {
    ...mapGetters({
      lang: "getLanguage",
      user: "getSessionUser",
      shoppingList: "getMyShoppingsList",
      park: "getCurrentParkByDomain",
      accTools: "getAccesibilityTool",
    }),
  },
  watch: {
    user() {
      this.accTools.restart(this.lang);
    }
  },
  mounted() {
    this.accTools.restart(this.lang);
  },
  methods: {
    getRandomId() {
      return uuidv4();
    },
    redirectToSearch() {
      this.$router.push({ name: "SearchPage" }).catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
.template-tickets {
  height: 100%;
}

.search-button {
  padding: 12px 10px;
  border-radius: 5px;
  display: block;
  background: var(--color-turquoise);
  border: 1px solid var(--color-blue);
  color: #fff;
  width: 100%;
  margin: 0 24px;
  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    background: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    i {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
  i {
    color: #fff !important;
  }
}
</style>