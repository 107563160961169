<template>
  <v-card
    elevation="1"
    class="card-ticket"
    :style="`border-bottom-color: ${getInfoByStatus(ticket.ESTADO).color}`"
  >
    <v-card-title class="d-flex gap-2">
      <h4
        :style="{
          fontSize: '14px',
          wordBreak: 'break-word',
          lineHeight: '1.2',
        }"
      >
        <strong>#{{ ticket.IDCOMPRA }}</strong> -
        {{ ticket.DESCRIPCION_CATEGORIA }}
      </h4>
    </v-card-title>
    <v-card-text class="pb-0">
      <div
        class="image-container d-flex gap-3"
        :style="{ gap: '15px', alignItems: 'center' }"
      >
        <!-- :src="loadImg(ticket.URLFOTO_CATEGORIA)" -->
        <img
          v-if="ticket.URLFOTO_CATEGORIA"
          width="100"
          height="100"
          :style="{ objectFit: 'cover' }"
          draggable="false"
          :src="loadImg(ticket.URLFOTO_CATEGORIA)"
          alt=""
        />
        <div>
          <!-- <p class="text-detail text-medium fw-500 mb-2">
             <strong>{{ labels.purchaseNumber[lang] }}:</strong>

            
          </p> -->
          <div
            v-if="
              ticket.HABILITARCOMPRAANTICIPADA == '1' &&
                ticket.HABILITARCAMBIOFECHA == '0'
            "
          ></div>
          <p v-else class="text-detail text-medium fw-500 mb-2">
            <strong>{{ labels.visitDate[lang] }}:</strong>
            <span>{{ ticket.FECHAVISITA }}</span>
          </p>
          <p class="text-detail text-medium fw-500 mb-2">
            <strong>{{ labels.amount[lang] }}:</strong>
            <span>S/ {{ ticket.IMPORTE }}</span>
          </p>
          <p
            class="text-detail text-medium fw-700 mt-4"
            :style="`color: ${getInfoByStatus(ticket.ESTADO).color}`"
          >
            {{ getInfoByStatus(ticket.ESTADO).message[lang] }}
          </p>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <!-- <v-divider :style="`border-color: ${getInfoByStatus(ticket.ESTADO).color}`"></v-divider> -->
      <v-spacer></v-spacer>
      <v-btn
        :color="getInfoByStatus(ticket.ESTADO).color"
        depressed
        rounded
        text
        :to="
          redirectTo
            ? redirectTo
            : {
                name: 'TicketDetail',
                params: {
                  purchaseId: ticket.IDCOMPRA,
                  parkId: ticket.IDPARQUE,
                },
              }
        "
        >{{ getTextBtnByTicket(ticket).message[lang] }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { imgService } from "@/libs/media";
export default {
  props: {
    ticket: Object,
    redirectTo: Object,
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
  },
  data() {
    return {
      labels: {
        visitDate: {
          en: "Visit date",
          es: "Fecha de visita",
        },
        amount: {
          en: "Total",
          es: "Importe total",
        },
        purchaseNumber: {
          en: "Purchase number",
          es: "Número de compra",
        },
        btnDetail: {
          en: "See detail",
          es: "Ver detalle",
        },
        btnTicketToUse: {
          en: "Use ticket",
          es: "Usar entrada",
        },
        defaultMessage: {
          en: "",
          es: "",
        },
        errorMessage: {
          en: "Your purchase could not be processed",
          es: "Tu compra no se pudo procesar",
        },
        pendingPayment: {
          en: "Your purchase is pending payment",
          es: "Tu compra esta pendiente de pago",
        },
        pendingLotery: {
          en:
            "Your registration was successful, wait for the results of the giveaway.",
          es:
            "Tu registro se realizó con éxito, espere los resultados del sorteo.",
        },
        successMessage: {
          en: "Your purchase was successful",
          es: "Tu compra se realizó con éxito",
        },
        successRegister: {
          en: "Your registration was successful",
          es: "Tu registro se realizó con éxito",
        },
        successLotery: {
          en:
            "Your registration was successful, you are the winner of the raffle.",
          es: "Tu registro se realizó con éxito, eres ganador del sorteo.",
        },
        cancelMessage: {
          en: "Your purchase has been canceled",
          es: "Tu compra ha sido cancelada",
        },
      },
    };
  },
  methods: {
    getInfoByStatus(status) {
      const ticket = this.ticket;

      switch (parseInt(status)) {
        case 1:
          if (ticket.HABILITARSORTEO == 0) {
            return {
              color: "orange",
              message: this.labels.pendingPayment,
            };
          } else {
            return {
              color: "#074882",
              message: this.labels.pendingLotery,
            };
          }
        case 2:
          if (ticket.PERMITIRGRATUITAS == "0") {
            return {
              color: "#00a39c",
              message: this.labels.successMessage,
            };
          } else {
            if (ticket.HABILITARSORTEO == "0") {
              return {
                color: "#074882",
                message: this.labels.successRegister,
              };
            } else {
              return {
                color: "#074882",
                message: this.labels.successLotery,
              };
            }
          }
        case 3:
          return {
            color: "orange",
            message: this.labels.cancelMessage,
          };
        case 4:
          return {
            color: "#af0000",
            message: this.labels.errorMessage,
          };
        default:
          return {
            color: "#074882",
            message: this.labels.defaultMessage,
          };
      }
    },
    getTextBtnByTicket(ticket) {
      let message = {};
      //let color = "accent"

      if (ticket.PASADO == 1) {
        if (ticket.ESTADO == 4) {
          //color = " accent ";
          message = this.labels.btnDetail;
        } else if (ticket.ESTADO == 2) {
          //color = " primary ";
          message = this.labels.btnTicketToUse;
        } else {
          //color = "";
          message = this.labels.btnDetail;
        }
      } else {
        message = this.labels.btnDetail;
      }
      return {
        //color,
        message,
      };
    },
    loadImg(filename = "") {
      return imgService(filename);
    },
  },
};
</script>

<style lang="scss">
.card-ticket {
  border-width: 3px;
  border-style: solid;
  box-shadow: -1px 4px 74px -16px rgba(0, 0, 0, 0.3) !important;
  .image-container {
    @media screen and (max-width: 400px) {
      img {
        width: 80px;
        height: 90px;
      }
    }
  }
  .text-detail {
    opacity: 1;
    color: #444;
    display: flex;
    gap: 10px;
    strong {
      min-width: 100px;
    }
    span {
      opacity: 0.85;
      font-weight: 400;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    @media screen and (max-width: 340px) {
      flex-wrap: wrap;
    }
  }
}
</style>
